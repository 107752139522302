import React from 'react';
import { initialiseMap } from '../../services/map.service';
import { useEffect } from 'react';

import './map.scss';

export default function Map() {
  useEffect(() => {
    initialiseMap();
  }, []);

  return (
    <div>
      <div className="row" id="mapContainer">
        <div
          id="levitate-map"
          className="levitate-map"
        />
      </div>
      <link
        href='https://api.mapbox.com/mapbox-gl-js/v1.4.1/mapbox-gl.css'
        rel='stylesheet'
      />
      <link
        rel='stylesheet'
        href='https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-directions/v4.0.2/mapbox-gl-directions.css'
        type='text/css'
      />
    </div>
  );
}
