import React from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';

import './navbar-link.scss';

interface NavbarLinkProps {
  children: React.ReactNode,
  to: string,
  noEffects?: boolean,
  onClick?: () => void,
}
export default function NavbarLink(props: NavbarLinkProps) {
  const {
    children,
    noEffects,
    onClick,
    to,
  } = props;

  const location = useLocation();

  function formSelector(): string {
    const base = 'nav-link';
    const variants: string[] = [];
    if (noEffects) {
      variants.push('no-effects');
    } else if (location.pathname === to) {
      variants.push('active');
    }

    return [
      base,
      ...variants.map((variant: string) => `${base}--${variant}`),
    ].join(' ');
  }

  const selector: string = formSelector();
  return (
    <Link
      className={selector}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
