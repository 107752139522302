import React from 'react';
import { constructImagesUrl } from '../../services/asset.service';
import DescriptiveImage from '../../components/descriptive-image/descriptive-image';
import InfoCard from '../../components/core/containers/info-card/info-card';
import BannerImage from '../../components/core/images/banner/banner';
import Testimonial from '../../components/testimonial/testimonial';
import Helmet from 'react-helmet';
import { config } from '../../lookups/config';
import { Link } from 'react-router-dom';
import { RoutesLookup } from '../../lookups/routes.lookup';

import './homepage.scss';

interface ITestimonial {
  comment: string,
  name: string,
  title: string,
}
const testimonials: ITestimonial[] = [
  {
    title: 'Extremely Knowledgeable',
    comment: 'Being a rugby player I am in need of recovery sports massage & injury treatment on a regular basis! Charlotte is extremely knowledgeable in both recovery & rehabilitation & is always willing to go the extra mile for her clients! Would highly recommend!',
    name: 'Ben S',
  },
  {
    title: 'Friendly',
    comment: 'Charlotte was really knowledgeable and friendly. She identified my issues and was able to release the tension in my back, neck and unknown to me, chest. I felt much much better after and have booked a second sports massage already.',
    name: 'Lea D',
  },
  {
    title: 'Highly Recommended',
    comment: 'Charlotte is an excellent sports massage therapist and clearly has a vast knowledge and experience. I would highly recommend her.',
    name: 'Angela S',
  },
  {
    title: 'Impressive',
    comment: 'Fabulous treatment! Within days of my treatment my HORRIBLE shoulder injury is forgotten! Really impressed with Charlotte and thanking my lucky stars she saw me struggling at work after hurting myself and said to pop and see her, best thing I did!',
    name: 'Daniella E',
  },
  {
    title: 'Great Value for Money',
    comment: 'The overall experience was very professional, welcoming and knowledgeable. Charlotte is very informative with her approach, whilst creating a calm atmosphere and engaging in what you body needs to improve. I would highly recommend her and on top of everything, she is great value for money!',
    name: 'Owen A',
  },
  {
    title: 'Fantastic',
    comment: 'Charlotte swiftly located the offending muscle that had shortened and was in complete spasm. She worked hard to release it, I came out feeling released from the acute pain and back on the road to recovery. Charlotte taught me further exercises in order to complete the recovery. I totally recommend Charlotte, she is fantastic.',
    name: 'Dan T',
  },
];

const treatments: string[] = [
  'Joint & Muscle Pain',
  'Sports Specific Injuries',
  'Postural Related Problems',
  'Repetitive Strain Injury',
  'Lower Back Pain',
  'General Accident Recovery'
];

export default function HomePage() {
  const charlottePortraitUrl = constructImagesUrl('charlotte-binney/portrait.jpg');

  return (
    <div>
      <Helmet>
        <title>Effective Sports Massage at an Affordable Price | {config.titleSuffix}</title>
        <meta name="description" content="A Bespoke Sports Massage Therapy Providing Great Service to Customers in the Rushden Area." />
      </Helmet>

      <div className="row">
        <div id="mainImage">
          <img
            src="/images/branding/banner.jpg"
            alt="Levitate Branding"
          />
        </div>
      </div>

      <div className="row padding-bottom">
        <div className="col-12 d-flex flex-row flex-wrap justify-content-around margin-top-large margin-bottom-large">
          <HomepageDescriptiveImage
            description="Treatment to create a personal preventative plan using educated knowledge about your body to help avoid further damage"
            imageSrc="treatments/prevent-injury.jpg"
            title="Prevent Injury"
          />
          <HomepageDescriptiveImage
            description="Improved quality of soft tissue repair helping to increase your range of movement and manage pain effectively"
            imageSrc="treatments/sports-massage-back-small.jpg"
            title="Recover Quicker"
          />
          <HomepageDescriptiveImage
            description="Increased functionality of the soft tissue enabling you to train harder and achieve greater results"
            imageSrc="treatments/gym-achieve-more.jpg"
            title="Achieve More"
          />
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-sm-10 offset-sm-1">
          <InfoCard>
            <h1 className="underlined underlined-heavy mb-4">Sports Massage</h1>
            <section>
              <p className="mb-1"><b>What is Sports Massage?</b></p>
              <p>
                Sports massage isn&apos;t just for performance athletes or people who are active, it can be beneficial to everyone.
                Using deeper pressure than general holistic massage therapies, sports massage is aimed at correcting
                imbalances in the soft tissue to improve muscular function and everyday well-being. Manual massage techniques
                are used combined with specific stretching techniques to help facilitate muscle relaxation, reduce pain and
                recover from potential injury.
              </p>
              <p>
                Levitate performance provides sports massages throughout the Northamptonshire area, over a range of
                {' '}
                <Link to={RoutesLookup.Contact}>opening hours</Link>
                {' '}
                to fit your needs
              </p>
            </section>
            <section>
              <p className="mb-1"><b>What can Sports Massage help to relieve?</b></p>
              <ul className="squared-list">
                {treatments.map((treatment: string) => (<li key={treatment}>{treatment}</li>))}
              </ul>
            </section>
          </InfoCard>
        </div>
      </div>

      <div className="row margin-top-extra-large margin-bottom-extra-large">
        <div className="col-sm-10 offset-sm-1">
          <InfoCard className="no-padding">
            <div className="row">
              <div className="d-flex flex-row flex-wrap justify-content-center col-12">
                <div className="padding-container-large d-flex flex-column justify-content-between col-12 col-md-6">
                  <div className="mb-4 flex-fill">
                    <h1 className="underlined underlined-heavy mb-4">Charlotte Binney</h1>
                    <p>
                      Charlotte prides herself on confidently assessing problematic areas of discomfort to ensure all clients feel
                      well informed and educated about the treatment they are receiving.
                    </p>
                    <p>
                      Alongside extensive knowledge and experience gained as a professional dancer and a personal trainer, Charlotte
                      is also a level 5 qualified soft tissue therapist which is the UK&apos;s highest recognised qualification.
                    </p>
                    <p>
                      Charlotte will treat and significantly improve soft tissue dysfunction with sports massage enabling clients
                      to improve their movement quality and overall general health and wellbeing. This combined with exercise
                      prescription tailored to the individual enables clients to receive a complete recovery plan avoiding
                      repetitive discomfort.
                    </p>
                  </div>
                  <div className="mx-auto d-flex flex-column align-items-center">
                    <img
                      className="mb-4"
                      src={constructImagesUrl('institute-of-sport-and-remedial-massage.png')}
                      style={{ width: '50%' }}
                      alt="Institute of Sport and Remedial Massage Logo"
                    />
                    <img
                      src={constructImagesUrl('complementary-and-natural-healthcare-council.png')}
                      style={{ width: '50%' }}
                      alt="Complementary and Natural Healthcare Council Logo"
                    />
                  </div>
                </div>
                <div className="d-flex flex-row flex-fill justify-content-end col-12 col-md-6 no-padding">
                  <img
                    src={charlottePortraitUrl}
                    style={{ minWidth: '300px', width: '100%', maxWidth: '800px' }}
                    alt="Charlotte Binney Portrait"
                  />
                </div>
              </div>
            </div>
          </InfoCard>
        </div>
      </div>

      <div className="row background-default margin-top-extra-large margin-bottom-extra-large padding-container-large">
        <div className="col-12">
          <div className="row">
            <div className="col-12 padding-bottom-large">
              <h2 className="text-center">What our customers have to say</h2>
            </div>
          </div>
          <div className="testimonials-container">
            {testimonials.map((testimonial: ITestimonial, index: number) => {
              const {
                comment,
                name,
                title,
              } = testimonial;

              const positionString = index % 2 === 0 ? 'right' : 'left';

              return (
                <div className="row" key={index}>
                  <div className="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
                    <Testimonial
                      title={title}
                      text={comment}
                      name={name}
                      namePosition={positionString}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

interface HomepageDescriptiveImageProps {
  description: string,
  imageSrc: string,
  title: string,
}
function HomepageDescriptiveImage(props: HomepageDescriptiveImageProps) {
  const {
    description,
    imageSrc,
    title,
  } = props;

  return (
    <DescriptiveImage
      className="mt-4 mb-4"
      type="primary"
      style={{ width: '26%', minWidth: '300px' }}
    >
      <BannerImage src={imageSrc} />
      <div className="text-center">
        <h2 className="underlined">{title}</h2>
      </div>
      <p>{description}</p>
    </DescriptiveImage>
  );
}
