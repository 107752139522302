/* eslint-disable import/extensions */
/* eslint-disable import/no-webpack-loader-syntax */
// @ts-ignore
import mapboxgl from '!mapbox-gl';
// @ts-ignore
import * as MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';

const accessToken = 'pk.eyJ1IjoiamFja2Jpbm5leSIsImEiOiJjanJ4d2J6OHUwcGRlM3ltMWs1dG51am51In0.EmsivVeC2e1Y3Q0QBqsrYg';

const levitatePos: mapboxgl.LngLatLike = {
  lat: 52.298294400623966,
  lng: -0.5858961382745619,
};

export function initialiseMap(showOffice?: boolean) {
  mapboxgl.accessToken = accessToken;
  const map = new mapboxgl.Map({
    container: 'levitate-map',
    style: 'mapbox://styles/mapbox/streets-v11',
    center: levitatePos,
    zoom: 11,
  });

  if (!map) return;

  map.on('load', function() {
    map.resize();
  });

  // Directions to be re-enabled once a fixed location is established, not just home visits
  if (showOffice) {
    const directions = buildDirections();
    map.addControl(directions, 'top-left');
    // Destination to be established at a later date
    // directions.setDestination();

    const marker: HTMLDivElement = buildMarker();
    new mapboxgl.Marker(marker)
      .setLngLat(levitatePos)
      .addTo(map);
  }

  return map;
}

function buildDirections() {
  return new MapboxDirections({
    accessToken: accessToken,
    profile: 'mapbox/driving',
    styles: [
      {
        'id': 'directions-destination-point',
        'type': 'circle',
        'source': 'directions',
        'paint': {
          'circle-radius': 18,
          'circle-color': 'red',
        },
        'filter': [
          'all',
          ['in', '$type', 'Point'],
          ['in', 'marker-symbol', 'B'],
        ],
      }, {
        'id': 'directions-destination-label',
        'type': 'symbol',
        'source': 'directions',
        'layout': {
          'text-field': 'L',
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        'paint': {
          'text-color': '#fff',
        },
        'filter': [
          'all',
          ['in', '$type', 'Point'],
          ['in', 'marker-symbol', 'B'],
        ],
      },
    ],
  });
}

function buildMarker(): HTMLDivElement {
  const mapMarkerSelector = 'marker';
  const marker = document.createElement('div');
  marker.className = mapMarkerSelector;

  const markerImage = document.createElement('div');
  markerImage.className = `${mapMarkerSelector}__image`;
  // TODO: resolve this cleaner
  markerImage.style.backgroundImage = `url('/images/branding/map-icon.jpg')`;
  marker.appendChild(markerImage);

  const markerText = document.createElement('p');
  markerText.className = `${mapMarkerSelector}__text`;
  markerText.textContent = 'Levitate';
  marker.append(markerText);

  return marker;
}
