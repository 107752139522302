import React, { useState } from 'react';
import { RoutesLookup } from '../../lookups/routes.lookup';
import { ContactLookup } from '../../lookups/contact-lookup';
import { IPage } from '../../interfaces/IPage';
import { Collapse } from 'react-bootstrap';
import NavbarLink from './navbar-link';
import { Link } from 'react-router-dom';

import './navbar.scss';
import NavbarContactLink from './navbar-contact-link';

const pages: IPage[] = [
  {
    name: 'Home',
    route: RoutesLookup.Home,
  },
  {
    name: 'Services',
    route: RoutesLookup.Services,
  },
  {
    name: 'Book Online',
    route: RoutesLookup.Booking,
  },
  {
    name: 'General Info',
    route: RoutesLookup.GeneralInfo,
  },
  {
    name: 'Contact',
    route: RoutesLookup.Contact,
  },
];

export default function Navbar() {
  const [open, setOpen]: [boolean, Function] = useState(false);

  function toggleCollapseState(): void {
    setOpen(!open);
  }

  function onLinkClick(): void {
    setOpen(false);
  }

  const collapseId = 'levitate-navbar-collapse';
  const selector = 'levitate-navbar';
  return (
    <div style={{
      position: 'fixed',
      width: '100%',
      zIndex: 100,
      top: 0,
    }}>
      <nav className={`${selector} navbar navbar-expand-lg navbar-light bg-light`}>
        <Link
          className={`${selector}__branding-group`}
          to={RoutesLookup.Home}
        >
          <span className="navbar-brand">LEVITATE</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapseState}
          aria-controls={collapseId}
          aria-expanded={open}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <Collapse in={open}>
          <div className="navbar-collapse">
            <div className="lhs">
              {
                pages.map((page: IPage) => {
                  const {
                    name,
                    route,
                  } = page;

                  return (
                    <div
                      key={`horizontal-navbar-link-${name}`}
                      className={`${selector}__link`}
                    >
                      <NavbarLink
                        to={route}
                        onClick={onLinkClick}
                      >
                        <span>{name}</span>
                      </NavbarLink>
                    </div>
                  );
                })
              }
            </div>
            <div className="rhs">
              <NavbarContactLink
                icon="call"
                text={ContactLookup.Phone}
              />
              <NavbarContactLink
                icon="mail"
                text={ContactLookup.Email}
              />
            </div>
          </div>
        </Collapse>
      </nav>
    </div>
  );
}
