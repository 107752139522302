import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import './faq-question.scss';

interface QuestionProps {
  question: string,
  answer: string,
}
export default function FaqQuestion(props: QuestionProps) {
  const {
    question,
    answer,
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <div className="row mb-2">
      <div className="col-12">
        <p
          className="faq-question"
          data-toggle="collapse"
          onClick={() => setOpen(!open)}
        >
          {question}
        </p>
        <Collapse in={open}>
          <p>{answer}</p>
        </Collapse>
      </div>
    </div>
  );
}
