import React from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { RoutesLookup } from '../../lookups/routes.lookup';

const routesToHide = [
  RoutesLookup.Booking,
  RoutesLookup.Policies,
];

export default function BookingButton() {
  const location = useLocation();

  if (routesToHide.indexOf(location.pathname) !== -1) {
    return null;
  }

  return (
    <div className="row margin-top-extra-large margin-bottom-extra-large">
      <div className="d-flex flex-column align-items-center col-12">
        <Link to={RoutesLookup.Booking} className="btn custom-button mb-3">Book an Appointment</Link>
        <div className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="5e062c24405a0d000104a764" data-style-height="24px" data-style-width="100%" data-theme="light">
          <a href="https://uk.trustpilot.com/review/levitateperformance.co.uk" target="_blank" rel="noopener noreferrer">Trustpilot</a>
        </div>
      </div>
    </div>
  );
}
