import React from 'react';
import BannerImage from '../../components/core/images/banner/banner';
import InfoCard from '../../components/core/containers/info-card/info-card';
import IconCard from '../../components/icons/icon-card/icon-card';
import { config } from '../../lookups/config';
import Helmet from 'react-helmet';
import FaqQuestion from '../../components/faq-question/faq-question';

import './info-page.scss';

interface FAQEntry {
  question: string,
  answer: string,
}
const faqs: FAQEntry[] = [
  {
    question: `I don't play any sports, can I still have a sports massage?`,
    answer: `Yes anyone can benefit from a sports massage. Muscle tension can build up through incorrect posture and repetitive movements seen in everyday life not just 'sport'.`,
  },
  {
    question: `How many treatments will I need?`,
    answer: `Everyone responds to treatment differently and the treatment delivered will be based on the current condition of the soft tissue and the length of time you have been experiencing discomfort. In several cases, follow up exercises and advice will be given to help alleviate symptoms and improve the condition outside of the massage session.`,
  },
  {
    question: `What should I expect?`,
    answer: `It is known that sports massages can be uncomfortable, but your therapist will always work within your comfort zone to avoid deliberate discomfort and pain. To ensure you receive an effective treatment, your therapist will combine manual techniques with assisted stretching to encourage extensibility of the tissues. You will be well informed throughout the treatment of what the therapist is doing and why, encouraging a complete recovery service that is personal to your circumstances. Everyone responds differently to treatment, sports massage is not one size fits all.`,
  },
  {
    question: `What do I wear to the appointment?`,
    answer: `Please wear anything you feel comfortable in - active wear or soft leggings/trousers are advised to aid the therapist with any stretching you may receive. You may be required to remove your clothes down to your underwear but you will be covered in a towel at all times. If this is not possible due to mobility or on religious grounds, your therapist will work within personal boundaries to offer effective treatment where possible.`,
  },
  {
    question: `Does sports massage hurt?`,
    answer: `As mentioned above, sports massages can be uncomfortable due to the use of deeper techniques, but never deliberately painful. Your therapist will work with your own breathing and relaxation to apply as much or as little pressure required for an effective treatment.`,
  },
  {
    question: `Can you treat and diagnose injuries?`,
    answer: `Sports massage is not a medical profession however your therapist has had extensive training within academic limits to provide educated information regarding injury diagnosis and treatment. Your therapist will always explain if they do not know the answer or feel you need to be referred to another professional. Levitate performance works closely with highly recommended medical professionals within the Rushden area.`,
  },
  {
    question: `What is the difference between deep tissue massage and sports massage?`,
    answer: `Deep tissue massage and sports massage are similar in pressure but sports massage offers greater benefits. Sports massage incorporates different techniques that assesses the muscle length to help lengthen the tissue back to full function. These techniques are beneficial particularly to soft tissue that has suffered injury but general use of these techniques that are not seen in holistic massages can provide positive ongoing results outside of the manual treatment.`
  },
];

export default function InfoPage() {
  return (
    <div>
      <Helmet>
        <title>Improved Muscular Function and Everyday Well-being | {config.titleSuffix}</title>
        <meta
          name="description"
          content="A Bespoke Sports Massage Therapy Providing Great Service to Customers in the Rushden Area."
        />
      </Helmet>

      <div className="row">
        <BannerImage
          className="col-12"
          src="stretches.jpg"
          type="large"
        >
          <h1 className="text-center">General Info</h1>
        </BannerImage>
      </div>

      <div className="row">
        <div className="col-12 col-sm-10 offset-sm-1 padding-top-extra-large padding-bottom-extra-large">
          <InfoCard>
            <div className="row">
              <div className="col-12 mb-4">
                <h1 className="underlined underlined-heavy">FAQs</h1>
              </div>
            </div>
            {faqs.map((faq: FAQEntry, index: number) => {
              const {
                question,
                answer,
              } = faq;

              return (
                <FaqQuestion
                  key={index}
                  question={question}
                  answer={answer}
                />
              );
            })}
          </InfoCard>
        </div>
      </div>

      <div className="row background-primary">
        <div className="d-flex flex-row flex-wrap justify-content-around col-12 col-sm-10 offset-sm-1">
          <IconCard
            className="consultation-step"
            iconType="custom"
            iconName="1"
            colourType="secondary"
            title="Initial Consultation"
            description="Range of movement and strength of specific muscle groups will be tested. A soft tissue massage will be conducted to determine the cause of dysfunction and begin to alleviate tension."
          />
          <IconCard
            className="consultation-step"
            iconType="custom"
            iconName="2"
            colourType="secondary"
            title="Follow-up Appointment"
            description="Manual massage techniques are used combined with specific stretching to help facilitate muscle relaxation, lengthen the muscle tissue, reduce pain, and recover from injury."
          />
          <IconCard
            className="consultation-step"
            iconType="custom"
            iconName="3"
            colourType="secondary"
            title="Exercise Correction"
            description="Functional based exercises are used to encourage the muscle tissue to remodel, helping to avoid episodes of pain and dysfunction through compensatory movement patterns."
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-10 offset-sm-1 padding-top-extra-large padding-bottom-extra-large">
          <InfoCard>
            <h1 className="underlined underlined-heavy mb-4">Aftercare Advice</h1>
            <p>
              During a massage treatment, the muscle tissue is stimulated to help remove waste products and break down
              adhesions and scar tissue.</p>
            <p className="mb-1">
              These are some <b>side effects</b> you may experience after a massage treatment:
            </p>
            <ul>
              <li>Increased thirst</li>
              <li>Stiffness/soreness within the muscle tissue</li>
              <li>Feelings of fatigue</li>
              <li>Heightened emotional state</li>
            </ul>

            <p><i>These after effects should last no longer than 36 hours.</i></p>
            <p className="mb-1"><b>After a massage, it is advisable to:</b></p>
            <ul>
              <li>
                Drink plenty of water to rehydrate the body and help flush out waste products that have been released
              </li>
              <li>Rest and relax to allow the muscles to respond to the treatment</li>
              <li>
                When leaving the appointment wear warm clothing to maintain the heat generated within the appointment.
                This is to increase circulation and retain the extensibility of the tissues
              </li>
            </ul>
          </InfoCard>
        </div>
      </div>
    </div>
  );
}
