import React from 'react';

import './loader.scss';

interface Props {
  style?: React.CSSProperties,
  type?: 'large',
}

export default function Loader(props: Props) {
  const {
    style,
    type,
  } = props;

  return (
    <div
      className="d-flex flex-row flex-fill justify-content-center align-items-center"
      style={style}
    >
      <div className={`loader ${type ?? ''}`} />
    </div>
  );
}
