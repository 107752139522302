import React from 'react';
import InfoCard from '../../components/core/containers/info-card/info-card';
import Map from '../../components/map/map';
import BannerImage from '../../components/core/images/banner/banner';
import Helmet from 'react-helmet';
import { config } from '../../lookups/config';
import ClinikoBookings from '../../components/cliniko/cliniko-bookings';

export default function BookingPage() {
  return (
    <div>
      <Helmet>
        <title>Sports Massage Booking in Rushden | {config.titleSuffix}</title>
        <meta name="description" content="Book a Sports Massage Appointment With Us Now for Our Rushden Clinic. We Accept Bookings for 30 Minutes, 45 Minutes, 60 Minutes, and 90 Minutes up to 3 Months Away." />
      </Helmet>
      <div className="row">
        <BannerImage className="col-12" src="book-online.jpg" type="large" style={{ backgroundPositionY: '-330px' }}>
          <h1 className="text-center">Book an Appointment</h1>
        </BannerImage>
      </div>
      <div className="row mt-5 mb-5">
        <div className="col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
          <div className="row">
            <div className="col-12">
              <InfoCard>
                <ClinikoBookings />
              </InfoCard>
            </div>
          </div>
        </div>
      </div>

      <Map />
    </div>
  );
}
