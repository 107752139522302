import React from 'react';

import './testimonial.scss';

interface Props {
  name: string,
  text: string,
  title: string,
  className?: string,
  namePosition?: PermittedPosition,
}

type PermittedPosition = 'right' | 'left';

export default function Testimonial(props: Props) {
  const {
    className,
    name,
    namePosition,
    text,
    title,
  } = props;

  const requestedNamePosition: PermittedPosition = namePosition ?? 'right';

  return (
    <div className={`testimonial ${className ?? ''} ${requestedNamePosition}`}>
      <div className="testimonial-content">
        <p className="underlined">{title}</p>
        <p>{text}</p>
      </div>
      <p className="testimonial-by">{name}</p>
    </div>
  );
}
