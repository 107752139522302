import React, { useState } from 'react';
import InfoCard from '../core/containers/info-card/info-card';

interface Props {
  children: React.ReactNode,
  title: string,
  clickToShow?: boolean,
}

export default function PolicySection(props: Props) {
  const {
    title,
    children,
    clickToShow,
  } = props;

  const [expanded, setExpanded] = useState(!clickToShow);

  return (
    <InfoCard>
      <h1 className="underlined underlined-heavy">{title}</h1>
      {
        clickToShow
          ? (
            <>
              {
                expanded
                  ? children
                  : (
                    <p>
                      <i
                        className="clickable"
                        onClick={() => setExpanded(true)}
                      >
                        Click to Show
                      </i>
                    </p>
                  )
              }
            </>
          ) : children
      }
    </InfoCard>
  );
}
