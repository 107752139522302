import React, { useState } from 'react';
import Loader from '../core/loader/loader';

export default function ContactForm() {
  const [nameValue, setNameValue] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');

  function resetErrors(): void {
    setNameError('');
    setEmailError('');
    setMessageError('');
  }

  async function onSubmitButtonClicked(): Promise<void> {
    resetErrors();

    const newNameError: string | undefined = validateName();
    if (newNameError) {
      setNameError(newNameError);
    }

    const newEmailError: string | undefined = validateEmail();
    if (newEmailError) {
      setEmailError(newEmailError);
    }

    const newMessageError: string | undefined = validateMessage();
    if (newMessageError) {
      setMessageError(newMessageError);
    }

    const hasErrored: boolean = !!newNameError || !!newEmailError || !!newMessageError;
    if (!hasErrored) {
      await submit();
    }
  }

  function validateName(): string | undefined {
    const nameRegex = /^[a-z ,.'-]+$/i;
    if (!nameValue || !nameValue.length) {
      return 'Please enter a name';
    } else if (!nameRegex.test(nameValue)) {
      return 'Please enter a valid name';
    }
  }

  function validateEmail(): string | undefined {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email || !email.length) {
      return 'Please enter your email';
    } else if (!emailRegex.test(email)) {
      return 'Please enter a valid email';
    }
  }

  function validateMessage(): string | undefined {
    if (!message) {
      return 'Please enter a message';
    } else if (message.length < 10) {
      return 'Please enter a message of decent length';
    }
  }

  async function submit(): Promise<void> {
    setIsSubmitting(true);
    try {
      const { ok } = await fetch('/api/contact.php', {
        method: 'POST',
        body: JSON.stringify({
          name: nameValue,
          email,
          message,
        }),
      });

      if (ok) {
        setHasSubmitted(true);
      } else {
        handleSubmissionError();
      }
    } catch (error) {
      handleSubmissionError(error.response?.errors);
    }
    setIsSubmitting(false);
  }

  function handleSubmissionError(errors?: string[]): void {
    const errorMessage: string[] = errors ?? ['An unexpected error has occurred, please try again later'];
    setSubmitErrorMessage(errorMessage.join(', '));

    setTimeout(() => {
      setSubmitErrorMessage('');
    }, 3000);
  }

  return (
    <>
      <div>
        <h2 className="underlined margin-bottom">Send us a message</h2>
      </div>
      {hasSubmitted || submitErrorMessage
        ? (
          <div className="row">
            <div className="col-12 text-center">
              {hasSubmitted
                ? <p>Thanks for sending us a message, {nameValue}, we&apos;ll be in touch as soon as we can!</p>
                : <p className="error">{submitErrorMessage}</p>
              }
            </div>
          </div>
        )
        : (
          <>
            {
              isSubmitting
                ? <Loader />
                : (
                  <div>
                    <form name="contactForm" action="">
                      <div className="row">
                        <div className="form-group col-12 col-sm-6">
                          <FieldLabel>Name</FieldLabel>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            value={nameValue}
                            onKeyDown={(e) => setNameValue(e.currentTarget.value)}
                            onChange={(e) => setNameValue(e.target.value)}
                          />
                          <FieldError>{nameError}</FieldError>
                        </div>
                        <div className="form-group col-sm-6">
                          <FieldLabel>Email</FieldLabel>
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            value={email}
                            onKeyDown={(e) => setEmail(e.currentTarget.value)}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <FieldError>{emailError}</FieldError>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-12">
                          <FieldLabel>Message</FieldLabel>
                          <textarea
                            className="form-control"
                            rows={5}
                            placeholder="How can we help you?"
                            name="message"
                            value={message}
                            onKeyDown={(e) => setMessage(e.currentTarget.value)}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                          <FieldError>{messageError}</FieldError>
                        </div>
                      </div>
                    </form>
                    <div className="row">
                      <div className="d-flex flex-row justify-content-center col-12">
                        <button
                          className="btn custom-button"
                          onClick={onSubmitButtonClicked}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )
            }
          </>
        )
      }
    </>
  );
}

interface FieldLabelProps {
  children: string,
}
function FieldLabel(props: FieldLabelProps) {
  const { children } = props;

  return (
    <label>
      {children}
      {' '}
      <span className="required-field" />
    </label>
  );
}

interface FieldErrorProps {
  children: string,
}
function FieldError(props: FieldErrorProps) {
  const { children } = props;

  return (
    <span className="error">{children}</span>
  );
}
