import React from 'react';
import { ContactLookup } from '../../lookups/contact-lookup';
import Helmet from 'react-helmet';
import { config } from '../../lookups/config';
import PolicySection from '../../components/policies-section/policy-section';

export default function PoliciesPage() {
  return (
    <div>
      <Helmet>
        <title>Clinic Policies | {config.titleSuffix}</title>
        <meta name="description" content="Levitate Takes Data Security Very Seriously, We Will Never Sell Your Information to a Third Party." />
      </Helmet>

      <div className="row" style={{ paddingTop: '66px' }}>
        <div className="col-12 col-sm-10 offset-sm-1 padding-top-large padding-bottom-large">
          <PolicySection
            title="Coronavirus (COVID-19) Terms and Conditions"
          >
            <p>
              <b>The Client</b><br />
              I, as the service user, will cancel my booking if I or any member of my household becomes ill
              with a fever, cough and/or breathing problems, or any symptoms suspected of COVID-19.
            </p>
            <p>
              I acknowledge that as the service user it is my responsibility to let Levitate Performance know
              this information willingly.
            </p>
            <p>
              The client is requested to wear a face covering at all times and to sanitise their hands before
              and after their treatment has ended. If the client removes the face covering during treatment,
              legally Levitate Performance is able to terminate your treatment. Where possible, both parties
              will work to ensure you are comfortable on the couch with a face covering in place. The
              therapist will provide clients with a face covering if they do not have one for no extra fee.
              The client is requested to open and close all internal doors on behalf of the therapist to prevent
              other members of your household coming into contact with the therapist during your treatment.
              The client will then continue to open and close all doors to minimise surface contact within your
              home.
            </p>
            <p>
              Levitate performance kindly asks that for all mobile treatments, clients provide their own towels.
              Due to infection control, we recommend these are washed at 60degrees, which can also be
              seen as recommended everyday practice for laundering towels and bedding.
            </p>
            <br />
            <p>
              <b>The Therapist</b><br />
              I, as the therapist, will remove the treatment couch from its cover outside before entering your
              property. When the treatment is over I will also remove the treatment couch before cleaning.
              The therapist will apply a face covering before entering your home and will remove all PPE
              according to PPE guidelines.
            </p>
            <p>
              The therapist will continue to sanitise hands before, during and after the treatment where
              necessary according to PPE guidelines
            </p>
          </PolicySection>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-10 offset-sm-1 padding-top-large padding-bottom-large">
          <PolicySection
            title="Services &amp; Fees"
          >
            <p>
              Services and fee&lsquo;s described by Levitate performance are set and non negotiable. This is to reflect the
              service delivered.
            </p>
          </PolicySection>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-10 offset-sm-1 padding-top-large padding-bottom-large">
          <PolicySection
            title="Cancellation Policy"
          >
            <p>
              Levitate performance is happy to cancel or reschedule any appointments up to 12 hours before your
              appointment time.
            </p>
            <p>
              A 50% cancellation fee applies for missed appointments or cancellations less than 12 hours prior to the
              appointment time.
            </p>
            <p>Regretfully appointments may not be completed to the correct duration if you do not arrive on time.</p>
          </PolicySection>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-10 offset-sm-1 padding-top-large padding-bottom-large">
          <PolicySection
            title="Code of Ethics, Conduct, and Practice"
            clickToShow
          >
            <div className="row">
              <div className="isrmBranding col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4" />
            </div>

            <h2>The Institute of Sport &amp; Remedial Massage (ISRM)</h2>
            <p>Below is taken from the Institute of Sport &amp; Remedial Massage</p>
            <p>
              Members of The Institute of Sport &amp; Remedial Massage, who have been awarded certification, having met
              the requirements, are required to uphold the professional standards outlined in the following code of ethics,
              conduct and practice.
            </p>

            <h2>Code of Ethics</h2>

            <p>Members are required to:</p>
            <ul>
              <li>
                Represent their qualifications honestly, including their educational achievements and professional
                affiliations, and will provide only those services they are qualified to perform.
              </li>
              <li>Be aware of contraindications for massage and bodywork, and refrain from treatment where appropriate.</li>
              <li>
                Acknowledge the limitations of sport and remedial massage and bodywork and refer clients on to appropriate
                health professionals when necessary.
              </li>
              <li>Provide treatment only where there is reasonable expectation that it will be advantageous to the client.</li>
              <li>Consistently maintain and improve their professional knowledge, developing a portfolio of education.</li>
              <li>
                Conduct their business and professional activities with honesty, integrity, and respect the views and
                beliefs of their clients in regard to gender, ethnic origins, cultural background, sexuality, lifestyle,
                age and social status.
              </li>
              <li>Refrain from unjust discrimination against clients or other healthcare professionals.</li>
              <li>
                Ensure that clients have an understanding about what is involved in their treatment and that voluntary
                informed consent is obtained either verbally or in a written form prior to treatment commencing.
              </li>
              <li>
                Respect the client&lsquo;s right to refuse or terminate treatment at any time, regardless to prior
                consent given.
              </li>
              <li>
                Members may exercise the right to refuse treatment to any person, or part of the body, for just and
                reasonable cause.
              </li>
              <li>
                Any ISRM member undertaking research must adhere to the code of ethics and standards laid down by the
                Education Research Trust.
              </li>
            </ul>

            <h2>Code of Conduct</h2>

            <h3>Professional</h3>
            <ul>
              <li>
                Members should be sensitive to a client&lsquo;s modesty at all times, and provide appropriate draping
                and treatment in a way that ensures their comfort and privacy.
              </li>
              <li>
                Physical examinations or treatments of children under the age of 16 (18 years for Scotland) must be in
                the presence of their parent or guardian. Treatment can be given in the presence of a chaperone, who
                must be a responsible adult, with written consent from the parent or guardian.
              </li>
              <li>
                Where a client is already receiving medical treatment for their presenting condition, treatment should
                not be commenced without prior consent from the respective practitioner.
              </li>
            </ul>

            <h3>Personal</h3>
            <p>
              Members who have been convicted of a criminal offence or have accepted a police caution for any of the following
              must inform ISRM. Each case will be considered individually and a decision made in the light of the circumstances
              of the case:
            </p>
            <ul>
              <li>Violence</li>
              <li>Abuse</li>
              <li>Sexual misconduct</li>
              <li>Supplying drugs</li>
              <li>Drink-driving offences where someone was hurt or killed</li>
              <li>Serious offences involving dishonesty</li>
              <li>Any serious criminal offences for which you received a prison sentence.</li>
            </ul>

            <h2>Code of Practice</h2>
            <h3>Records</h3>
            <ul>
              <li>
                Members should maintain contemporaneous and up-to-date records of all clients in regard to medical
                history, presenting complaint, assessment and procedures performed.
              </li>
              <li>The record keeping process should be as transparent as possible, and clients should be made aware that
                records are being kept.
              </li>
              <li>
                All records should be kept according to the 8 principles Data Protection Act (1998). These state that
                data must be:
                <ul>
                  <li>fairly and lawfully processed</li>
                  <li>processed for limited purposes</li>
                  <li>adequate, relevant and not excessive</li>
                  <li>accurate</li>
                  <li>not kept for longer than is necessary</li>
                  <li>processed in line with your rights</li>
                  <li>secure</li>
                  <li>not transferred to countries without adequate protection.</li>
                </ul>
              </li>
              <li>
                Where a member works as part of a group practice or clinic, members should ensure that the practice or
                clinic is registered under the Data Protection Act (1998) where appropriate.
              </li>
              <li>
                Records must be kept in a secure place, adequately safeguarded and not accessible to third parties.
                Computerised Records must be kept secure from unauthorised access. Members are advised to seek advice
                relating to the requirement to register under the Data Protection Act where client records are computerised.
              </li>
              <li>
                Members should safeguard the confidentiality of all client information.  Disclosure to third parties,
                including other healthcare professionals should be only with the client&lsquo;s written permission.
                (Where information is disclosed to other parties, advice should be sought relating to the requirement
                to register under the Data Protection Act. Where Credit Card payments are taken by individuals not covered
                by a group Data Protection Registration, it is recommended that advice is sought relating to the requirement
                to register.)
              </li>
              <li>
                Client records need to be kept for seven years, in the case of children 5 for years after their 21st
                birthday, for terminally or seriously ill clients records should be retained indefinitely.
              </li>
            </ul>

            <h3>Personal</h3>
            <ul>
              <li>
                Members must hold adequate professional insurance cover and must have a current first aid certificate.
                (minimum level &quot;Appointed Persons&quot;)
              </li>
              <li>Full members may use the designation ISRM after their name.</li>
            </ul>

            <h3>Premises</h3>
            <ul>
              <li>
                Treatment areas must be hygienic, safe and equipped to clinical standards. Any couch used must have an
                adequate working weight for the client and techniques used.
              </li>
              <li>Treatment rooms and public areas all must comply with current health and safety regulations.</li>
            </ul>

            <h3>Advertising</h3>
            <ul>
              <li>Any Advertising must be legal, decent, honest, truthful and in accordance with the British Code of
                Advertising Practice.
              </li>
              <li>Full Members may use the logo of the ISRM on business stationery &amp; cards.</li>
              <li>Nothing may be published in the name of ISRM without prior agreement from ISRM.</li>
            </ul>
          </PolicySection>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-10 offset-sm-1 padding-top-large padding-bottom-large">
          <PolicySection
            title="Privacy Policy"
            clickToShow
          >
            <h3>Scope</h3>
            <p>
              This document refers to personal data, which is defined as information concerning any living person (a natural
              person who hereafter will be called the Data Subject) that is not already in the public domain. The General Data
              Protection Regulation (GDPR) which is EU wide and far more extensive than its predecessor the Data Protection
              Act, along with the Privacy and Electronic Communications Regulations (PECR), seek to protect and enhance the
              rights of EU data subjects. These rights cover the safeguarding of personal data, protection against the unlawful
              processing of personal data and the unrestricted movement of personal data within the EU and its storage within
              the EEA.
            </p>

            <h3>1 - Your Practice, Levitate Performance</h3>
            <p>
              Based in Rushden, which hereafter for the purposes of this Privacy Notice will be referred to as Levitate
              Performance, is pleased to provide the following information:
            </p>

            <h3>2 - Who we are</h3>
            <p>Levitate Performance provides sports massage and exercise prescription for common musculoskeletal conditions.</p>

            <h3>3 - Personal Data</h3>
            <p>
              a) For the purposes of providing treatment, our practitioners may require detailed medical information. We will
              only collect what is relevant and necessary for your treatment. When you visit our practice, we will make notes,
              which may include details concerning your medication, treatment and other issues affecting your health. This
              data is always held securely, is not shared with anyone not involved in your treatment, although for data storage
              purposes it may be handled by pre-vetted staff that have all signed an integrity and confidentiality agreement.
              To be able to process your personal data it is a condition of any treatment that you give your explicit consent
              to allow Levitate Performance to document and process your personal medical data. Contact details provided by
              you such as telephone numbers, email addresses, postal addresses may be used to remind you of future appointments
              and provide reports or other information concerning your treatment. As part of our obligations as primary
              healthcare practitioners there may be circumstances related to your treatment, on-going care or medical
              diagnosis that will require the sharing of your medical records with other healthcare practitioners e.g. GPs,
              consultants, surgeons and/or medical insurance companies. Where this is required we will always inform you
              first unless we are under a legal obligation to comply.
            </p>
            <p>
              b) For internal marketing purposes provided you have opted-in, Levitate Performance may also use the contact
              details provided by you to respond to your enquiries, including making telephone contact and emailing information
              to you, which the practice believes may be of interest to you.
            </p>
            <p>
              c) In making initial contact with the practice you consent to Levitate Performance maintaining a marketing
              dialogue with you until you either opt out (which you can do at any time) or we decide to desist in promoting
              our services. Levitate Performance may occasionally also act on behalf of its patients in the capacity of data
              processor, when we may promote other practitioners based at our premises, who may not be employed by us. Levitate
              Performance does not broker your data and you can ask to be removed from our marketing database by emailing or
              phoning the practice using the contact details provided at the end of this Privacy Notice.
            </p>
            <p>
              d) Some basic personal data may be collected about you from the marketing forms and surveys you complete, from
              records of our correspondence and phone calls and details of your visits to our website, including but not limited
              to, personally identifying information like Internet Protocol (IP) addresses.
            </p>
            <p>
              e) Levitate Performance will only collect the information needed so that we can provide you with the services
              you require, the business does not sell or broker your data.
            </p>

            <h3>4 - Legal basis for processing any personal data</h3>
            <p>
              To meet our contractual obligations obtained from explicit Patient Consent and legitimate interest to respond to
              enquiries concerning the services provided.
            </p>

            <h3>5 - Legitimate interests pursued by Levitate Performance</h3>
            <p>To promote treatments for patients with all types of health problems indicated for MSK procedure care.</p>

            <h3>6 - Consent</h3>
            <p>
              Through agreeing to this privacy notice you are consenting to Levitate Performance processing your personal data
              for the purposes outlined. You can withdraw consent at any time by using the postal, email address or telephone
              number provided at the end of this Privacy Notice.
            </p>

            <h3>7 - Disclosure</h3>
            <p>
              Levitate Performance will keep your personal information safe and secure, only staff engaged in providing your
              treatment will have access to your patient records, although our administration team will have access to your
              contact details so that they can make appointments and manage your account. Levitate Performance will not disclose
              your Personal Information unless compelled to, in order to meet legal obligations, regulations or valid governmental
              requests. The practice may also enforce its Terms and Conditions, including investigating potential violations
              of its Terms and Conditions to detect, prevent or mitigate fraud or security or technical issues; or to protect
              against imminent harm to the rights, property or safety of its staff.
            </p>

            <h3>8 - Retention Policy</h3>
            <p>
              Levitate Performance will process personal data during the duration of any treatment and will continue to store
              only the personal data needed for eight years after the contract has expired to meet any legal obligations. After
              eight years all personal data will be deleted, unless basic information needs to be retained by us to meet our
              future obligations to you, such as erasure details. Records concerning minors who have received treatment will
              be retained until the child has reached the age of 25.
            </p>

            <h3>9 - Data Storage</h3>
            <p>All Data is held in the United Kingdom. Levitate Performance does not store personal data outside the EEA.</p>

            <h3>10 - Your rights as a data subject</h3>
            <p>
              At any point whilst Levitate Performance are in possession of, or processing your personal data, all data subjects
              have the following rights:
            </p>
            <ul>
              <li>Right of access - you have the right to request a copy of the information that we hold about you.</li>
              <li>
                Right of rectification - you have a right to correct data that we hold about you that is inaccurate or
                incomplete.
              </li>
              <li>
                Right to be forgotten - in certain circumstances you can ask for the data we hold about you to be
                erased from our records.
              </li>
              <li>
                Right to restriction of processing - where certain conditions apply you have a right to restrict the
                processing.
              </li>
              <li>
                Right of portability - you have the right to have the data we hold about you transferred to another
                organisation.
              </li>
              <li>Right to object - you have the right to object to certain types of processing such as direct marketing.</li>
              <li>
                Right to object to automated processing, including profiling - you also have the right not to be subject
                to the legal effects of automated processing or profiling.
              </li>
            </ul>
            <p>
              In the event that Levitate Performance refuses your request under rights of access, we will provide you with a
              reason as to why, which you have the right to legally challenge. At your request Levitate Performance can confirm
              what information it holds about you and how it is processed.
            </p>

            <h3>11 - You can request the following information:</h3>
            <ul>
              <li>
                Identity and the contact details of the person or organisation (Levitate Performance) that has
                determined how and why to process your data.
              </li>
              <li>Contact details of the data protection officer, where applicable.</li>
              <li>The purpose of the processing as well as the legal basis for processing.</li>
              <li>
                If the processing is based on the legitimate interests of Levitate Performance and information about
                these interests.
              </li>
              <li>The categories of personal data collected, stored and processed.</li>
              <li>Recipient(s) or categories of recipients that the data is/will be disclosed to.</li>
              <li>How long the data will be stored.</li>
              <li>Details of your rights to correct, erasure, restrict or object to such processing.</li>
              <li>Information about your right to withdraw consent at any time.</li>
              <li>How to lodge a complaint with the supervisory authority (ICO).</li>
              <li>
                Whether the provision of personal data is a statutory or contractual requirement, or a requirement
                necessary to enter into a contract, as well as whether you are obliged to provide the personal data and
                the possible consequences of failing to provide such data.
              </li>
              <li>The source of personal data if it wasn’t collected directly from you.</li>
              <li>
                Any details and information of automated decision making, such as profiling, and any meaningful
                information about the logic involved, as well as the significance and expected consequences of such
                processing.
              </li>
            </ul>

            <h3>12 - To access what personal data is held, identification will be required</h3>
            <p>
              Levitate Performance will accept the following forms of identification (ID) when information on your personal
              data is requested: a copy of your driving licence, passport, birth certificate and a utility bill not older than
              three months. A minimum of one piece of photographic ID listed above and a supporting document is required. If
              Levitate Performance is dissatisfied with the quality, further information may be sought before personal data
              can be released. All requests should be made to {ContactLookup.Email}
            </p>

            <h3>13 Complaints</h3>
            <p>
              In the event that you wish to make a complaint about how your personal data is being processed by Levitate
              Performance you have the right to complain to us. If you do not get a response within 30 days, you can complain
              to the ISRM.
            </p>
          </PolicySection>
        </div>
      </div>
    </div>
  );
}
