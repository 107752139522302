import React from 'react';
import BannerImage from '../../components/core/images/banner/banner';
import Map from '../../components/map/map';
import InfoCard from '../../components/core/containers/info-card/info-card';
import Helmet from 'react-helmet';
import { config } from '../../lookups/config';
import ContactForm from '../../components/contact-form/contact-form';

import './contact-page.scss';

interface OpeningTime {
  day: string,
  times: string,
}
const openingTimes: OpeningTime[] = [
  {
    day: 'Monday',
    times: '18:30 - 21:00',
  },
  {
    day: 'Tuesday',
    times: '18:30 - 21:00',
  },
  {
    day: 'Wednesday',
    times: 'Closed',
  },
  {
    day: 'Thursday',
    times: '18:30 - 21:00',
  },
  {
    day: 'Friday',
    times: '18:30 - 21:00',
  },
  {
    day: 'Saturday',
    times: '08:00 - 14:30',
  },
  {
    day: 'Sunday',
    times: 'Closed',
  },
];

export default function ContactPage() {
  return (
    <div>
      <Helmet>
        <title>Get In Touch | {config.titleSuffix}</title>
        <meta name="description" content="Contact Us via Email or Phone, We Will Respond as Quick as Possible. We Love to Hear Enquiries and Feedback from Our Customers." />
      </Helmet>
      <div className="row">
        <BannerImage
          className="col-12"
          src="treatments/sports-massage-back-large.jpg"
          type="large"
        >
          <h1 className="text-center">Contact Us</h1>
        </BannerImage>
      </div>
      <div className="row">
        <div className="d-flex flex-row col-12 col-lg-3 no-padding">
          <div
            className="d-flex flex-column justify-content-center align-items-center background-primary padding-container-large col-12"
            id="opening-hours-container"
          >
            <h3 className="text-center mb-4">Opening Hours</h3>
            <p className="text-center">
              <i>Providing Sports Massages in the Northamptonshire Area</i>
            </p>
            <table id="opening-hours-timetable">
              <tbody>
                {openingTimes.map((openingTime: OpeningTime) => {
                  const {
                    day,
                    times,
                  } = openingTime;

                  return <tr key={day}>
                    <td>{day}</td>
                    <td><b>{times}</b></td>
                  </tr>;
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="padding-container-extra-large col">
          <InfoCard>
            <ContactForm />
          </InfoCard>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Map />
        </div>
      </div>
    </div>
  );
}
