import React from 'react';

import './info-card.scss';

interface Props {
  children: React.ReactNode,
  className?: string,
}

export default function InfoCard(props: Props) {
  const {
    className,
    children,
  } = props;

  return (
    <div className={`info-card ${className ?? ''}`}>
      {children}
    </div>
  );
}
