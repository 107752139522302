import React from 'react';
import CircularIcon, { PermittedIconColourType, PermittedIconType } from '../circular-icon/circular-icon';

import './icon-card.scss';

interface Props {
  description: string,
  iconName: string,
  title: string,
  className?: string,
  colourType?: PermittedIconColourType,
  iconType?: PermittedIconType,
}

export default function IconCard(props: Props) {
  const {
    className,
    colourType,
    description,
    iconName,
    iconType,
    title,
  } = props;

  const requestedColourType: PermittedIconColourType = colourType ?? 'primary';

  return (
    <div className={`icon-card ${className ?? ''} ${requestedColourType}`}>
      <CircularIcon
        iconName={iconName}
        type={iconType}
        colourType={requestedColourType}
      />
      <h4 className="underlined">{title}</h4>
      <p>{description}</p>
    </div>
  );
}
