import React from 'react';
import SocialMediaIcon from '../icons/social-media-icon/social-media-icon';
import { RoutesLookup } from '../../lookups/routes.lookup';
import { Link } from 'react-router-dom';
import { ContactLookup } from '../../lookups/contact-lookup';
import { IPage } from '../../interfaces/IPage';

import './footer.scss';
import { constructImagesUrl } from '../../services/asset.service';

const pages: IPage[] = [
  {
    name: 'Home',
    route: RoutesLookup.Home,
  },
  {
    name: 'Book Online',
    route: RoutesLookup.Booking,
  },
  {
    name: 'Contact',
    route: RoutesLookup.Contact,
  },
  {
    name: 'Clinic Policies',
    route: RoutesLookup.Policies,
  },
];

export default function Footer() {
  return (
    <div className="footer row">
      <ul className="col-sm-4">
        {
          pages.map((page: IPage) => {
            const {
              name,
              route,
            } = page;

            return (
              <li key={name}>
                <Link to={route}>{name}</Link>
              </li>
            );
          })
        }
      </ul>
      <div className="col-sm-4 hidden-xs" style={{ textAlign: 'center' }}>
        <img
          src={constructImagesUrl('branding/banner-reversed.jpg')}
          alt="Levitate Logo"
        />
      </div>
      <ul className="col-sm-4">
        <li>
          <a href="contact">
            <span
              className="glyphicon glyphicon-earphone"
              style={{ top: '3px' }}
            />
            {ContactLookup.Phone}
          </a>
        </li>
        <li>
          <a href="contact">
            <span
              className="glyphicon glyphicon-envelope"
              style={{ top: '3px' }}
            />
            {ContactLookup.Email}
          </a>
        </li>
        <li className="mt-1">
          <SocialMediaIcon
            className="mr-2"
            type="facebook"
            href="https://www.facebook.com/levitateperformance/"
          />
          <SocialMediaIcon
            type="instagram"
            href="https://www.instagram.com/levitateperformance/"
          />
        </li>
      </ul>
      <p className="col-sm-12 text-center">Copyright &copy; Levitate {new Date().getFullYear()}</p>
    </div>
  );
}
