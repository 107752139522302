import React from 'react';
import { useState } from 'react';
import facebookPrimaryLogo from '../../../assets/images/social-media/facebook/primary.png';
import facebookHoverLogo from '../../../assets/images/social-media/facebook/secondary.png';
import instagramPrimaryLogo from '../../../assets/images/social-media/instagram/primary.png';
import instagramHoverLogo from '../../../assets/images/social-media/instagram/secondary.png';

import './social-media-icon.scss';

interface Props {
  href: string,
  type: PermittedType,
  className?: string,
}

type PermittedType = 'facebook' | 'instagram';
type ImageMap = Record<PermittedType, string>;

const imageMap: ImageMap = {
  facebook: facebookPrimaryLogo,
  instagram: instagramPrimaryLogo,
};
const hoverImageMap: ImageMap = {
  facebook: facebookHoverLogo,
  instagram: instagramHoverLogo,
};

export default function SocialMediaIcon(props: Props) {
  const {
    className,
    href,
    type,
  } = props;

  const [isHovering, setIsHovering] = useState(false);

  return (
    <a
      className={className}
      href={href}
      target="_blank"
      rel="noreferrer"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <img
        className="social-media-icon"
        src={(isHovering ? hoverImageMap : imageMap)[type]}
        alt={`${type} logo`}
      />
    </a>
  );
}
