import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line import/extensions
// import 'bootstrap/dist/js/bootstrap.js';
import './styles/style.scss';

import App from './app';

ReactDOM.render(<App />, document.getElementById('root'));
