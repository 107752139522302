import React, {
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  children: React.ReactNode,
}

export default function ScrollToTop(props: Props) {
  const { children } = props;

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
}
