import React from 'react';

export default function ClinikoBookings() {
  return (
    <>
      <iframe
        title="Levitate Cliniko Booking"
        id='cliniko-51028890'
        src='https://levitate-performance.au1.cliniko.com/bookings?embedded=true'
        frameBorder='0'
        scrolling='auto'
        width='100%'
        height='1000'
        style={{ pointerEvents: 'auto' }}
      />
      {
        window.addEventListener('message', function handleIFrameMessage(e) {
          var clinikoBookings: any = document.getElementById('cliniko-51028890');
          if (typeof e.data !== 'string') return;
          if (e.data.search('cliniko-bookings-resize') > -1) {
            var height = Number(e.data.split(':')[1]);
            clinikoBookings.style.height = height + 50 + 'px';
          }
          e.data.search('cliniko-bookings-page') > -1 && clinikoBookings.scrollIntoView();
        })
      }
    </>
  );
}
