import React from 'react';

import './circular-icon.scss';

interface Props {
  iconName: string,
  type?: PermittedIconType,
  colourType?: PermittedIconColourType,
}

export type PermittedIconColourType = 'primary' | 'secondary';
export type PermittedIconType = 'default' | 'icon' | 'custom';

export default function CircularIcon(props: Props) {
  const {
    colourType,
    iconName,
    type,
  } = props;

  const requestedColourType: PermittedIconColourType = colourType ?? 'primary';
  const requestedIconType: PermittedIconType = type ?? 'icon';

  return (
    <div className={`circular-icon mx-auto ${requestedColourType}`}>
      {
        requestedIconType === 'icon'
          ? <i className={iconName} />
          : <span>{iconName}</span>
      }
    </div>
  );
}
