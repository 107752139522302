import React from 'react';
import { RoutesLookup } from '../../lookups/routes.lookup';
import NavbarLink from './navbar-link';

interface Props {
  icon: string,
  text: string,
}
export default function NavbarContactLink(props: Props) {
  const {
    icon,
    text,
  } = props;

  return (
    <NavbarLink
      to={RoutesLookup.Contact}
      noEffects
    >
      <i className={`ionicon ion-ios-${icon}`} />
      <span className="ml-2">{text}</span>
    </NavbarLink>
  );
}
