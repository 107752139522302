import React from 'react';
import BannerImage from '../../components/core/images/banner/banner';
import IconCard from '../../components/icons/icon-card/icon-card';
import InfoCard from '../../components/core/containers/info-card/info-card';
import Helmet from 'react-helmet';
import { config } from '../../lookups/config';

interface Product {
  title: string,
  duration: number,
  price: number,
  isLowerBoundPrice?: boolean,
}
const products: Product[] = [
  {
    title: 'Sports Massage/Initial Appointment',
    duration: 60,
    price: 47.50,
  },
  {
    title: 'Sports Massage',
    duration: 30,
    price: 35,
  },
  {
    title: 'Sports Massage',
    duration: 45,
    price: 40,
  },
  {
    title: 'Sports Massage',
    duration: 90,
    price: 72,
  },
  {
    title: 'Sports Massage - Home Visit',
    duration: 60,
    price: 55,
    isLowerBoundPrice: true,
  },
  {
    title: 'One-to-One Exercise Rehabilitation Follow Up',
    duration: 45,
    price: 45,
  },
];

export default function ServicesPage() {
  return (
    <div>
      <Helmet>
        <title>What We Can Offer You | {config.titleSuffix}</title>
        <meta name="description" content="Levitate Offers High Quality Sports Massages, Corrective Exercises, and Personal Training at Reasonable Prices." />
      </Helmet>

      <div className="row">
        <BannerImage className="col-12"
          src="treatments/sports-massage-leg.jpg"
          type="large"
        >
          <h1 className="text-center">Services We Offer</h1>
        </BannerImage>
      </div>
      <div className="row background padding-container-large">
        <div className="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
          <p className="text-center">Sports massage isn&apos;t just for performance athletes or people who are active, anyone can benefit. Sports massage is aimed at correcting imbalances in the soft tissue to improve muscular function and everyday well-being. Your initial appointment will include several assessments to investigate the cause of discomfort and from here, an individual treatment plan will be designed based on the outcome of the findings. By following up manual treatment with rehabilitative exercise you will be able to consistently improve functional well-being, helping you remain fit for daily life.</p>
        </div>
      </div>

      <div className="text-center row background-primary">
        <IconCard className="col-12 col-sm-10 offset-sm-1 col-md-3 offset-md-2 mt-5 mb-5"
          title="Sports Massage"
          description="Manual massage techniques are used combined with specific stretching to help facilitate muscle relaxation, lengthen the muscle tissue, reduce pain, and recover from injury."
          colourType="secondary"
          iconName="ionicon ion-md-medkit"
        />
        <IconCard className="col-12 col-sm-10 offset-sm-1 col-md-3 offset-md-2 mt-5 mb-5"
          title="Rehabilitative Exercise"
          description="Functional based exercises are used to encourage the muscle tissue to remodel, helping to avoid further episodes of pain and dysfunction through compensatory movement patterns."
          colourType="secondary"
          iconName="ionicon ion-md-walk"
        />
      </div>

      <div className="row margin-top-extra-large">
        <div className="col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
          <InfoCard>
            <h1 className="underlined">Prices</h1>
            <p>All new patients are required to book a 60 minute session for their first appointment to allow time for a full case history to be taken.</p>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th><span>Session</span></th>
                  <th><span>Length</span></th>
                  <th><span>Price</span></th>
                </tr>
              </thead>
              <tbody>
                {products.map((product: Product) => {
                  const {
                    duration,
                    price,
                    title,
                    isLowerBoundPrice,
                  } = product;

                  return <tr key={title}>
                    <td>{title}</td>
                    <td>
                      {duration}
                      {' '}
                      Minutes
                    </td>
                    <td>{isLowerBoundPrice ? 'From ' : ''}&pound;{price}</td>
                  </tr>;
                })}
              </tbody>
            </table>
          </InfoCard>
        </div>
      </div>
    </div>
  );
}
