import React from 'react';

import './descriptive-image.scss';

interface Props {
  children: React.ReactNode,
  className: string,
  style: React.CSSProperties,
  type: any,
}

export default function DescriptiveImage(props: Props) {
  const {
    children,
    className,
    style,
    type,
  } = props;

  return (
    <div
      className={`descriptive-image text-center ${type} ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}
