import React from 'react';
import { constructImagesUrl } from '../../../../services/asset.service';

import './banner.scss';

interface Props {
  children?: React.ReactNode,
  className?: string,
  src: string,
  style?: React.CSSProperties,
  type?: PermittedTypes,
}

type PermittedTypes = 'default' | 'large';

export default function BannerImage(props: Props) {
  const {
    children,
    className,
    src,
    style,
    type,
  } = props;

  const imageSrc = constructImagesUrl(src);
  const computedStyles = { ...style };
  computedStyles.backgroundImage = `url('${imageSrc}')`;

  return (
    <div
      className={`banner ${className ?? ''} ${type === 'large' ? 'large' : ''}`}
      style={computedStyles}
    >
      {children}
    </div>
  );
}
