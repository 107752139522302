import React, {
  useEffect,
  useState,
} from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import HomePage from './pages/homepage/homepage.page';
import ServicesPage from './pages/services/services.page';
import BookingPage from './pages/booking/booking.page';
import ContactPage from './pages/contact/contact.page';
import InfoPage from './pages/info/info.page';
import PoliciesPage from './pages/policies/policies.page';
import BookingButton from './components/booking-button/booking-button';
import ScrollToTop from './components/core/scroll-to-top';
import Loader from './components/core/loader/loader';

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => setIsLoading(false), []);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <div className="container-fluid">
          <div className="row">
            <Navbar />
          </div>
          {isLoading ?
            <Loader type="large" style={{ minHeight: 'calc(100vh - 64px - 195px)' }} /> :
            <div>
              <Switch>
                <Route exact path="/">
                  <HomePage />
                </Route>
                <Route exact path="/services">
                  <ServicesPage />
                </Route>
                <Route exact path="/booking">
                  <BookingPage />
                </Route>
                <Route exact path="/contact">
                  <ContactPage />
                </Route>
                <Route exact path="/info">
                  <InfoPage />
                </Route>
                <Route exact path="/policies">
                  <PoliciesPage />
                </Route>
              </Switch>
              <BookingButton />
            </div>
          }
          <Footer />
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
}
